import { UpwardOpportunityTypes } from './upwardOpportunityTypes'
import store from '@/store'
import { computed } from '@vue/composition-api'

export const useProgramType = (type: UpwardOpportunityTypes) => {
  return computed(() => store.getters.leagueAbstraction.programType === type)
}

// Reactive convenience methods for common program type checks
export const useIsLeague = () => useProgramType(UpwardOpportunityTypes.LEAGUE)
export const useIsCamp = () => useProgramType(UpwardOpportunityTypes.CAMP)
export const useIsClinic = () => useProgramType(UpwardOpportunityTypes.CLINIC)
export const useIsRunningChallenge = () => useProgramType(UpwardOpportunityTypes.UPWARDRUNNING)
export const useIsUpwardSelect = () => useProgramType(UpwardOpportunityTypes.UPWARDSELECT)
export const useIsConnect = () => useProgramType(UpwardOpportunityTypes.CONNECT)
export const useIsLicensing = () => useProgramType(UpwardOpportunityTypes.LICENSING)
