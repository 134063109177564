



























import { defineComponent, computed, PropType } from '@vue/composition-api'
import InputLabel from '@/elements/InputLabel.vue'
import { isEmailValid } from '@/lib/support/utilities/email/emailUtil'
import { isPhoneValid } from '@/lib/support/utilities/phone/phoneUtil'
import { MessageChannelTypes } from '@/components/Communication/MessageChannelData'
import { useIsRunningChallenge } from '@/lib/common/programTypeHelpers'
import store from '@/store'
import uuid from 'uuid'

interface Recipient {
  id: string
  contact: string
  name: string
}

export default defineComponent({
  name: 'RecipientListPreview',
  components: { InputLabel },
  props: {
    recipientGroups: {
      type: Array as PropType<string[]>,
      default: () => [],
      required: false,
    },
    channel: {
      type: String,
      default: MessageChannelTypes.EMAIL,
      required: true,
    },
  },
  setup(props) {
    const isRunningChallenge = useIsRunningChallenge()
    const isEmailChannel = computed(() => props.channel.toLowerCase() === MessageChannelTypes.EMAIL)

    const groups = computed(() => {
      const selectedGroups = store.getters.communications.messagePreview.selectedGroups
      return selectedGroups.map((group) => ({ id: uuid.v4(), group }))
    })

    const createRecipient = (contact: string, name: string = contact): Recipient => ({
      id: uuid.v4(),
      contact,
      name,
    })

    const getAdditionalRecipients = computed(() => {
      const delimitedRecipients = store.getters.communications.messagePreview.toDelimited.split(',')
      return delimitedRecipients.map((r) => createRecipient(r))
    })

    const individualsRecreational = computed(() => {
      const messagePreviewRecipients = store.getters.communications.messagePreview.to
      return [...getAdditionalRecipients.value, ...messagePreviewRecipients.map((r) => createRecipient(r))]
    })

    const individualsRunning = computed(() => {
      const selectedRecipients = store.getters.communications.recipients
        .filter((recipient) => recipient.isSelected)
        .map((recipient) =>
          createRecipient(isEmailChannel.value ? recipient.email : recipient.phone, recipient.name)
        )

      return [...getAdditionalRecipients.value, ...selectedRecipients]
    })

    const individuals = computed(() =>
      isRunningChallenge.value ? individualsRunning.value : individualsRecreational.value
    )

    const recipientValid = (recipient: string): boolean => {
      if (!recipient) return false
      return isEmailChannel.value ? isEmailValid(recipient) : isPhoneValid(recipient)
    }

    const getRecipientClass = (contact: string) => ({
      'valid-pill': recipientValid(contact),
      'invalid-pill': !recipientValid(contact),
    })

    return {
      groups,
      individuals,
      getRecipientClass,
    }
  },
})
