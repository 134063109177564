export interface Recipients {
  selectedGroups: string[]
  toDelimited: string[] // Used in the call to send email and sms. Just the selected emails or phone numbers
  recipients: Recipient[] //List of all recipients with a flag indicating if they are selected or not
}

export interface Recipient {
  id: string | number
  name: string
  email: string
  phone: string
  isSelected: boolean
}

export function getEmptyRecipients(): Recipients {
  return {
    selectedGroups: [],
    toDelimited: [],
    recipients: [],
  }
}

export function getEmptyRecipient(): Recipient {
  return {
    id: '',
    name: '',
    email: '',
    phone: '',
    isSelected: false,
  }
}
